import React from 'react'
import { AmplifyProvider, Authenticator } from "@aws-amplify/ui-react";


import { App } from "./App";

export default function Index() {
  return (
    <AmplifyProvider>
      <Authenticator.Provider>
        <App />
      </Authenticator.Provider>
    </AmplifyProvider>
  )
}
